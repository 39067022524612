export const getUser = () => {
    const user = localStorage.getItem('user')
    return JSON.parse(user)
}

export const setUser = (person) => {
    const user = JSON.stringify(person)
    localStorage.setItem('user', user)
}

export const updateAuthStatus = (status) =>
    localStorage.setItem('auth_status', status)

export const getAuthStatus = () =>
    localStorage.getItem('auth_status')

export const logout = () => {
    localStorage.removeItem('jwt')
    localStorage.removeItem('user')
    localStorage.removeItem('auth_status')
}
