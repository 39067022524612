import React from 'react'
import { Dashboard } from '@mui/icons-material'
import Drawer from '@mui/material/Drawer'
import Toolbar from '@mui/material/Toolbar'
import List from '@mui/material/List'
import Divider from '@mui/material/Divider'
import ListItem from '@mui/material/ListItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import ListItemText from '@mui/material/ListItemText'
import { useNavigate } from 'react-router-dom'

const drawerWidth = 260

export default function Sidenav() {
    const navigate = useNavigate()

    const pages = [
        {
            name: 'welcome',
            displayName: 'Welcome',
            path: '/'
        },
        {
            name: 'page1',
            displayName: 'Authentication Step 1',
            path: '/1'
        }/*,
        {
            name: 'page2',
            displayName: 'Authentication Step 2',
            path: '/2'
        },*/
    ]

    return (
        <Drawer
            variant='permanent'
            sx={{
                width: drawerWidth,
                flexShrink: 0,
                [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
            }}
        >
            <Toolbar />
            <Divider />
            <List sx={{ pt: 3 }}>
                {pages.map((page, index) => (
                    <ListItem
                        button
                        key={index}
                        sx={{
                            color: window.location.pathname === page.path
                                ? 'primary.main': 'primary.secondary',
                        }}
                        onClick={() => navigate(page.path)}
                    >
                        <ListItemIcon
                            sx={{
                                color: window.location.pathname === page.path
                                    ? 'primary.main': 'primary.secondary',
                            }}
                        >
                            <Dashboard/>
                        </ListItemIcon>
                        <ListItemText primary={page.displayName} />
                    </ListItem>
                ))}
            </List>
        </Drawer>
    )
}
