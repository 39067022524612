import React from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    borderRadius: '.25rem',
    p: 3,
    minWidth: '20rem',
    maxWidth: '24rem'
};

export default function Message({ open, setOpen, alert, setAlert }) {
    const handleClose = () =>{
        setOpen(false)
        setAlert('')
    }
    if (!alert) return null
    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-alert-title"
            aria-describedby="modal-alert-description"
        >
            <Box sx={style}>
                <Typography variant="text" component="div" sx={{ m: 3 }}>
                    {alert}
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'flex-end'}}>
                    <Button
                        variant='contained'
                        size='small'
                        sx={{ width: '4rem' }}
                        onClick={handleClose}
                    >
                        OK
                    </Button>
                </Box>
            </Box>
        </Modal>
    )
}