import React, { useEffect, useState, useContext } from 'react'

import Box from '@mui/material/Box'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import Link from '@mui/material/Link'

import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../App'
import api from '../../util/api'
import Header from '../Header/Header'

const style = {
    text: {
        width: { lg: 'auto', xs: '70%' },
        textAlign: { lg: 'left', xs: 'center' }
    }
}

export default function Login() {
    const navigate = useNavigate()
    const [email, setEmail] = useState('')
    const [emailSent, setEmailSent] = useState(false)
    const { webJWT, showAlert, breakLg } = useContext(AppContext)

    useEffect(() => {
        if (webJWT) {
            navigate('/')
        }
    }, [webJWT, navigate])

    const handleLogin = async () => {
        if (!email) return
        const res = await api.login(email)
        if (!res) {
            showAlert('There was a problem with your request.')
            return
        }
        if (res.status < 299) {
            setEmailSent(true)
        } else {
            showAlert(res.data.message
                ? res.data.message
                : 'There was a problem with your request.'
            )
        }
    }

    const renderLogin = () => {
        if (emailSent) {
            return (
                <Typography color='text.secondary' sx={style.text}>
                    Thank you. Please check your email box for an email
                    from us (check spam folder too).
                </Typography>
            )
        }
        return (
            <>
                <Typography color='text.secondary' sx={style.text}>
                    Enter your email and we will send you secure login instructions.
                </Typography>
                <hr />
                <hr />
                <Box display='flex' alignItems='center'>
                    <TextField
                        placeholder='Email'
                        variant='outlined'
                        type='email'
                        size='small'
                        value={email}
                        onChange={(e) => setEmail(e.target.value)}
                        sx={{
                            width: 220,
                            '& input': {
                                fontSize: '.9em',
                                bgcolor: '#fff',
                                color: theme => theme.palette.text.secondary
                            }
                        }}
                    />
                    <Button
                        variant='contained'
                        sx={{
                            mx: 2,
                            height: '2.3rem',
                            width: '5rem',
                        }}
                        onClick={handleLogin}
                        disabled={email ? false : true}
                    >
                        Login
                    </Button>
                </Box>
            </>
        )

    }

    return (
        <Box
            component='main'
            display='flex'
            justifyContent={{ xs: 'flex-start', lg: 'center' }}
            alignItems='center'
            minHeight='100vh'
            flexDirection={{ xs: 'column', lg: 'row' }}
            sx={{ background: 'linear-gradient(180deg, #fff, 80%, #BF93F2)' }}
        >
            <Header />
            {breakLg && <Toolbar />}
            <Box
                width={{ lg: 600, xs: 'auto' }}
                height={{ lg: 280, xs: 'auto' }}
                display='flex'
                flexDirection='column'
                py={{ lg: 0, xs: 8 }}
            >
                <Typography
                    color='text.secondary'
                    fontSize={{ lg: 40, md: 30, xs: 24 }}
                    fontWeight={600}
                >
                    Help us make financial data <br />
                    more secure.
                </Typography>
                <Typography color='text.secondary' fontSize={{ lg: 12, xs: 10 }}>
                    Powered by &nbsp;
                    <Link href='https://pentadatainc.com' target='_blank' underline='none'>
                        Pentadata
                    </Link>
                    &nbsp; and &nbsp;
                    <Link href='https://akoya.com' target='_blank' underline='none'>
                        Akoya
                    </Link>
                </Typography>
            </Box>
            <Box
                component='div'
                padding={{ lg: '2rem', xs: 0 }}
                display='flex'
                justifyContent='center'
                flexDirection='column'
                alignItems={{ lg: 'flex-start', xs: 'center' }}
                width={{ lg: 340, xs: 'auto' }}
                height={{ lg: 'auto', xs: '15rem' }}
            >
                {renderLogin()}
            </Box>
        </Box>
    )
}
