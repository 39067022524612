import urljoin from 'url-join'
import { API_URL } from '../lib/config'

const getHeaders = (token) => {
    const headers = {
        'content-type': 'application/json',
        'Authorization': `Bearer ${token}`
    }
    return headers
}

const api = {

    login: async (email) => {
        try {
            const res = await fetch(urljoin(API_URL, '/web/login'), {
                method: 'post',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email })
            })
            const data = await res.json()
            return  {
                status: res.status,
                data
            }
        }
        catch(e) {
            console.log('Error logging in', e)
            return null
        }
    },

    personGet: async (token) => {
        try {
            const res = await fetch(urljoin(API_URL, '/users-pentadata'), {
                method: 'get',
                headers: getHeaders(token)
            })
            if (res.status === 200) {
                const data = await res.json()
                return data
            }
            return null
        }
        catch(e) {
            console.log('Error getting person', e)
            return null
        }
    },

    personCreate: async (token, payload) => {
        try {
            const res = await fetch(urljoin(API_URL, `/users-pentadata`), {
                method: 'post',
                headers: getHeaders(token),
                body: JSON.stringify(payload)
            })
            const data = await res.json()
            return data
        }
        catch(e) {
            console.log('Error creating person', e)
            return null
        }
    },

    accountsCreate: async (token, pid, payload) => {
        try {
            const res = await fetch(urljoin(API_URL, `/users-pentadata/${pid}/bank-accounts`), {
                method: 'post',
                headers: getHeaders(token),
                body: JSON.stringify(payload)
            })
            if (res.status === 200) {
                const data = await res.json()
                return data.url
            }
            return null
        }
        catch(e) {
            console.log('Error creating accounts', e)
            return null
        }
    },

    accountsGet: async (token, pid) => {
        try {
            const res = await fetch(urljoin(API_URL, `/users-pentadata/${pid}/bank-accounts`), {
                method: 'get',
                headers: getHeaders(token)
            })
            if (res.status === 200) {
                const data = await res.json()
                return data
            }
            return null
        }
        catch(e) {
            console.log('Error getting accounts', e)
            return null
        }
    },

    authenticate: async (token, pid, payload) => {
        try {
            const res = await fetch(urljoin(API_URL, `/users-pentadata/${pid}/authenticate`), {
                method: 'post',
                headers: getHeaders(token),
                body: JSON.stringify(payload)
            })
            const data = await res.json()
            return { data, status: res.status }
        }
        catch(e) {
            console.log('Error authenticating person', e)
            return null
        }
    },

    verify: async (token, pid, payload) => {
        try {
            const res = await fetch(urljoin(API_URL, `/users-pentadata/${pid}/verify`), {
                method: 'post',
                headers: getHeaders(token),
                body: JSON.stringify(payload)
            })
            const data = await res.json()
            return { data, status: res.status }
        }
        catch(e) {
            console.log('Error authenticating person', e)
            return null
        }
    },

}

export default api
