import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import {
    Box,
} from '@mui/material'
import { setJWT } from '../../util/jwt'

export default function LoadJWT() {
    const navigate = useNavigate()
    const { jwt } = useParams()

    useEffect(() => {
        if (jwt) {
            setJWT(jwt)
        }
        navigate('/')
    }, [jwt, navigate])

    return (
        <Box
            component='main'
            display='flex'
            justifyContent='center'
            alignItems='center'
            minHeight='100vh'
            bgcolor='#212121'
        >
        </Box>
    )
}
