import React, { useState, useContext } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import TextField from '@mui/material/TextField'
import LoadingButton from '@mui/lab/LoadingButton'

import { AppContext } from '../../App'
import api from '../../util/api'
import { setUser } from '../../util/user'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function PersonForm({ open, setOpen, openNextModal }) {
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [loading, setLoading] = useState(false)

    const { webJWT, decodedJWT } = useContext(AppContext)
    const handleClose = () => setOpen(false)

    const handleContinue = async () => {
        setLoading(true)
        try {
            const data = {
                email: decodedJWT.email,
                first_name: firstName,
                last_name: lastName
            }
            const person = await api.personCreate(webJWT, data)
            if (person) {
                setOpen(false)
                openNextModal(true)
                setUser(person)
            }
        }
        catch(e) {
            console.log(e)
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-person-form-title"
            aria-describedby="modal-person-form-description"
        >
            <Box sx={style}>
                <Typography id="modal-person-title" variant="h6" component="h2">
                    Personal Information
                </Typography>
                <Box
                    component='form'
                    sx={{
                        bgcolor: 'background.paper',
                        '& .MuiTextField-root': { m: 1 },
                        display: 'flex',
                        p: 2
                    }}
                >
                    <TextField
                        required
                        id='person-form-first-name'
                        label='First Name'
                        size='small'
                        value={firstName}
                        onChange={(e) => setFirstName(e.target.value)}
                    />
                    <TextField
                        required
                        id='person-form-last-name'
                        label='Last Name'
                        size='small'
                        value={lastName}
                        onChange={(e) => setLastName(e.target.value)}
                    />
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <LoadingButton
                        size='small'
                        variant='contained'
                        onClick={handleContinue}
                        disabled={!firstName || !lastName}
                        loading={loading}
                    >
                        CONTINUE
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    )
}