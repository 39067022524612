import './App.css'
import React, {
    createContext,
    useEffect,
    useState,
    useCallback
} from 'react'
import {
    BrowserRouter as Router,
    Routes,
    Route,
} from 'react-router-dom'
import Home from './components/Page/Home'
import BankAuth from './components/Page/BankAuth'
import Login from './components/Page/Login'
import LoadJWT from './components/Page/LoadJWT'
import MessageModal from './components/Modal/Message'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { getJWT, verifyJWT, decodeJWT } from './util/jwt'
import { setUser, getAuthStatus, logout } from './util/user'
import api from './util/api'
import { FRONTEND_URL, DEFAULT_REDIRECT_URI } from './lib/config'
import AdapterMoment from '@mui/lab/AdapterMoment'
import LocalizationProvider from '@mui/lab/LocalizationProvider'
import useMediaQuery from '@mui/material/useMediaQuery'
// import { CssBaseline } from '@mui/material'

export const AppContext = createContext()

const theme = createTheme({
    palette: {
        primary: {
            main: '#6414EC'
        }
    },
    typography: {
        fontFamily: 'Overpass'
    },
    components: {
        MuiButton: {
            styleOverrides: {
                contained: {
                    textTransform: 'none'
                },
                outlined: {
                    textTransform: 'none'
                }
            }
        }
    }
})

const drawerWidth = 240

const App = () => {
    const [webJWT, setWebJWT] = useState(null)
    const [decodedJWT, setDecodedJWT] = useState(null)
    const [personData, setPersonData] = useState(null)
    const [personAuth, setPersonAuth] = useState(null)
    const [bankAccounts, setBankAccounts] = useState(null)
    const [alert, setAlert] = useState('')
    const [alertModal, setAlertModal] = useState(false)
    const [loadingBanks, setLoadingBanks] = useState(false)

    const breakSm = useMediaQuery(theme.breakpoints.down('sm'))
    const breakMd = useMediaQuery(theme.breakpoints.down('md'))
    const breakLg = useMediaQuery(theme.breakpoints.down('lg'))

    const getToken = async () => {
        const token = getJWT()
        if (!token) return

        const verify = await verifyJWT(token)
        if (!verify) return
        return token
    }

    const handleLogout = () => {
        setWebJWT(null)
        setDecodedJWT(null)
        setPersonData(null)
        setPersonAuth(null)
        setBankAccounts(null)
        logout()
    }

    const getPerson = useCallback(async (token) => {
        if (personData) return personData
        const decodedToken = decodeJWT(token)
        const person = await api.personGet(token, decodedToken.email)
        if (person) {
            setPersonData(person)
            setUser(person)
            return person
        }
        return null
    }, [personData])

    useEffect(() => {
        const init = async () => {
            const token = await getToken()
            if (!token) return
            setWebJWT(token)
            setDecodedJWT(decodeJWT(token))

            await getPerson(token)

            const authStatus = getAuthStatus()
            if (authStatus) {
                setPersonAuth(authStatus)
            }
        }
        init()
    }, [getPerson])

    const getBankLink = async ({ pid, bankId, redirectUri, source }) => {
        const data = {
            bank_id: bankId,
            source,
            redirect_uri: FRONTEND_URL.includes('localhost')
                ? DEFAULT_REDIRECT_URI
                : redirectUri
        }
        const url = await api.accountsCreate(webJWT, pid, data)
        return url
    }

    const getBankAccounts = useCallback(async (pid) => {
        setLoadingBanks(true)
        const accounts = await api.accountsGet(webJWT, pid)
        if (accounts) {
            setBankAccounts(accounts)
        }
        setLoadingBanks(false)
        return accounts
    }, [webJWT])

    const showAlert = (message) => {
        if (!message) return
        setAlert(message)
        setAlertModal(true)
    }

    const value = {
        getToken,
        handleLogout,
        showAlert,
        drawerWidth,
        webJWT,
        decodedJWT,
        personData,
        setPersonData,
        personAuth,
        setPersonAuth,
        getPerson,
        getBankLink,
        getBankAccounts,
        bankAccounts,
        loadingBanks,
        breakSm,
        breakMd,
        breakLg
    }

    return (
        <ThemeProvider theme={theme}>
            <AppContext.Provider value={value}>
                {/* <CssBaseline /> */}
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Router>
                        <Routes>
                            {webJWT ?
                                <>
                                    <Route path='/' element={<Home />} />
                                    <Route
                                        path='/1'
                                        element={
                                            <BankAuth
                                                source='akoya'
                                                aggId={process.env.REACT_APP_AGG_1_ID}
                                                nextPage='/2'
                                            />
                                        }
                                    />
                                    <Route
                                        path='/2'
                                        element={
                                            <BankAuth
                                                source='finicity'
                                                aggId={process.env.REACT_APP_AGG_2_ID}
                                            />
                                        }
                                    />
                                    <Route path='*' element={<Home />} />
                                </> :
                                <>
                                    <Route path='/login' element={<Login />} />
                                    <Route path='/login/:jwt' element={<LoadJWT />} />
                                    <Route path='*' element={<Login />} />
                                </>
                            }
                        </Routes>
                    </Router>
                    <MessageModal
                        open={alertModal}
                        setOpen={setAlertModal}
                        alert={alert}
                        setAlert={setAlert}
                    />
                </LocalizationProvider>
            </AppContext.Provider>
        </ThemeProvider>
    )
}

export default App
