import * as React from 'react'
import Box from '@mui/material/Box'
import Header from '../Header/Header'
import Nav from '../Nav/Nav'

export default function PageWrapper({ children }) {
    return (
        <Box sx={{ display: 'flex' }}>
            <Header />
            <Nav />
            {children}
        </Box>
    )
}
