import React, { useContext, useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Toolbar from '@mui/material/Toolbar'
import Button from '@mui/material/Button'
import CircularProgress from '@mui/material/CircularProgress'
import PageWrapper from '../PageWrapper/PageWrapper'
import { AppContext } from '../../App'
import BankSelect from '../Modal/BankSelect'
import PersonForm from '../Modal/PersonForm'
import AccountsTable from '../Table/AccountsTable'

export default function BankAuth({ source, aggId, nextPage }) {
    const {
        webJWT,
        getPerson,
        personData,
        getBankAccounts,
        bankAccounts,
        loadingBanks,
    } = useContext(AppContext)
    const [openBankSelect, setOpenBankSelect] = useState(false)
    const [openPersonForm, setOpenPersonForm] = useState(false)
    const [aggAccounts, setAggAccounts] = useState([])
    const navigate = useNavigate()

    const authenticateBank = async () => {
        try {
            const person = await getPerson(webJWT)
            if (person) {
                setOpenBankSelect(true)
                return
            }
            setOpenPersonForm(true)
        }
        catch(e) {
            console.log(e)
            //dosomething
        }
    }

    useEffect(() => {
        if (webJWT && personData) {
            getBankAccounts(personData.id)
        }
    }, [webJWT, personData, getBankAccounts])

    useEffect(() => {
        if (bankAccounts && bankAccounts.length) {
            const reduced = bankAccounts.reduce((filtered, account) => {
                if (account.cids.includes(parseInt(aggId))) {
                    filtered.push(account)
                }
                return filtered
            }, [])
            setAggAccounts(reduced)
        }
    }, [bankAccounts, aggId])

    const showAccountsTable = () => {
        if (loadingBanks) {
            return(
                <Box sx={{ p: 2, minHeight: '1rem', display: 'flex', justifyContent: 'center' }}>
                    <CircularProgress size={20}/>
                </Box>
            )
        }

        if (!aggAccounts.length) {
            return(
                <Box>
                    <Typography color='text.secondary' sx={{ my: 2 }}>
                        No accounts found, authenticated bank accounts will display here.
                    </Typography>
                </Box>
            )
        }
        console.log('Showing accounts.');
        return(
            <Box sx={{ minHeight: '1rem', display: 'flex', justifyContent: 'center' }}>
                <AccountsTable data={aggAccounts} />
            </Box>
        )
    }

    const showNextStep = () => {
        if (!nextPage) return
        if (!aggAccounts.length) return
        return(
            <Box>
                <Typography color='text.secondary' sx={{ my: 2 }}>
                    Thanks for adding your accounts
                </Typography>
            </Box>
        )
    }

    const modals = (
        <>
            <BankSelect
                open={openBankSelect}
                setOpen={setOpenBankSelect}
                source={source}
            />
            <PersonForm
                open={openPersonForm}
                setOpen={setOpenPersonForm}
                openNextModal={setOpenBankSelect}
            />
        </>
    )

    return (
        <PageWrapper>
            <Box
                component='main'
                sx={{
                    width: '100%',
                    bgcolor: 'background.default',
                    p: 3,
                    '& button': { px: 2, py: 1 }
                }}
            >
                <Toolbar />
                <Box>
                    <Typography variant='body1' color='text.secondary' component='div'>
                        Please verify your bank accounts. On your bank website, select to share as many accounts as you can.
                    </Typography>
                    <br/>
                    <Typography variant='body1' color='text.secondary' component='div'>
                        We ask you to keep the data access open for Bitdoor for the next several weeks. Thank you!
                    </Typography>
                    <br/>
                    <br/>
                    <Button
                        size='small'
                        variant='contained'
                        onClick={() => authenticateBank()}
                    >
                        Authenticate Bank Account
                    </Button>
                </Box>
                {showAccountsTable()}
                {showNextStep()}
            </Box>
            {modals}
        </PageWrapper>
    )
}
