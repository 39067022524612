import React, { useState, useContext } from 'react'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import List from '@mui/material/List'
import ListItemText from '@mui/material/ListItemText'
import ListItemButton from '@mui/material/ListItemButton'
import LoadingButton from '@mui/lab/LoadingButton'

import bankList from '../../lib/bankList.json'
import { AppContext } from '../../App'
import { FRONTEND_URL } from '../../lib/config'

const style = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export default function BankSelect({ open, setOpen, source }) {
    const [selectedBank, setSelectedBank] = useState({})
    const [loading, setLoading] = useState(false)
    const handleClose = () => setOpen(false)
    const { getPerson, getBankLink, webJWT, showAlert } = useContext(AppContext)

    const handleContinue = async () => {
        setLoading(true)
        const person = await getPerson(webJWT)
        try {
            const url = await getBankLink({
                pid: person.id,
                bankId: selectedBank.id,
                redirectUri: `${FRONTEND_URL}/${window.location.pathname}`,
                source
            })
            if (url) {
                window.open(url, '_blank')
            } else {
                showAlert('There was a problem with your request.')
            }
        }
        catch(e) {
            console.log(e)
        }
        finally {
            setLoading(false)
        }
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Select Bank
                </Typography>
                <Box sx={{ width: '100%', bgcolor: 'background.paper' }}>
                    <List
                        component='div'
                        aria-label="main mailbox folders"
                        sx={{ m: '1rem 0' }}
                    >
                        {bankList.map((b, idx) => (
                            <ListItemButton
                                key={`bank-list-${idx}`}
                                selected={selectedBank.id === b.id}
                                onClick={() => setSelectedBank(b)}
                            >
                                <ListItemText primary={b.bank} />
                            </ListItemButton>
                        ))}
                    </List>
                </Box>
                <Box sx={{ display: 'flex', flexDirection: 'row-reverse' }}>
                    <LoadingButton
                        size='small'
                        variant='contained'
                        onClick={handleContinue}
                        disabled={!Object.keys(selectedBank).length}
                        loading={loading}
                    >
                        CONTINUE
                    </LoadingButton>
                </Box>
            </Box>
        </Modal>
    )
}