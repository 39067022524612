import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import { formatDate } from '../../util/date'

export default function AccountsTable({ data }) {
  return (
    <TableContainer component={Paper} sx={{ my: 2 }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead sx={{ '& th': { fontWeight: '700', color: 'primary.main' } }}>
          <TableRow>
            <TableCell>Account</TableCell>
            <TableCell>Bank</TableCell>
            <TableCell>Type&nbsp;</TableCell>
            <TableCell>Last Opt In&nbsp;</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map((row) => (
            <TableRow
              key={row.account_id}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
              <TableCell component="th" scope="row">
                {row.account_name}
              </TableCell>
              <TableCell>{row.bank}</TableCell>
              <TableCell>{row.account_type}</TableCell>
              <TableCell>{formatDate(row.last_opt_in)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}
