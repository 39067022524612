import React, { useEffect, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { AppContext } from '../../App'
import {
    Box,
    Toolbar,
    Typography
} from '@mui/material'
import PageWrapper from '../PageWrapper/PageWrapper'

export default function Home() {
    const navigate = useNavigate()
    const { webJWT } = useContext(AppContext)

    useEffect(() => {
        if (!webJWT) {
            navigate('/login')
        }
    }, [webJWT, navigate])

    return (
        <PageWrapper>
            <Box
                component='main'
                sx={{ flexGrow: 1, bgcolor: 'background.default', px: 5, py: 3 }}
            >
                <Toolbar />
                <Typography variant='h2' color='text.secondary' noWrap component='div'>
                    Welcome
                </Typography>
	        <br/>
                <Typography variant='body1' color='text.secondary' component='div'>
                    Thank you for participating in this data connection test. Akoya, LLC and Bitdoor (a Pentadata App). have partnered
	            to make data-sharing easier and more secure, with your help.
                </Typography>
	        <br/>
	        <Typography variant='body1' color='text.secondary' component='div'>
                    During this test, we will ask you to connect your bank accounts to our application.
	            The connection is <b>secure and approved by the banks</b>. You will confirm this by logging-in
	            on your bank's website.
	        </Typography>
	        <br/>
                <Typography variant='body1' color='text.secondary' component='div'>
	            When you will link the bank account, you will give consent to share data with Bitdoor/Pentadata, Inc. By continuing this test, you also agree that Bitdoor will share the same data, or part of, with Akoya and that you understand Akoya will use it to improve the data-sharing user experience. Personally identifiable information will be masked in any data shared with Akoya.
	        </Typography>
	        <br/>
                <Typography variant='body1' color='text.secondary' component='div'>
	            Please share all the accounts that you feel comfortable with and review <a href="https://akoya.com/privacy-policy">Akoya's privacy policy</a> and <a href="https://www.pentadatainc.com/privacy-policy/">Pentadata's privacy policy</a>. 
                </Typography>
	        <br/>
	        <Typography variant='body1' color='text.secondary' component='div'>
	            We ask you to kindly keep the data access open for the next several weeks. Thank you!
	        </Typography>
            </Box>
        </PageWrapper>
    )
}
